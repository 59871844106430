<template>
<div>
<div v-if="loading">Loading</div>
<div :class="['main', page.main_image.image_url ? 'content-page' : 'content-page-no-sidebar', 'page']">
    <div v-if="page.main_image.image_url" class="content-sidebar no-sidebar-background">
        <img :src="page.main_image.image_url" :alt="page.description + ' main image'" />
        <div class="caption">{{ page.main_image.description }}</div>
    </div>
    <div :class="['content-page-inner', page.main_image.image_url ? 'normal-sidebar' : '']">
        <div v-if="page.header_image" class="top-banner"><img :src="page.header_image" :alt="page.content_type_code + ' header image'" /></div>
        <h1>{{ page.description }}</h1>
        <div v-if="page.author.name" class="byline"><label>Author:</label> {{ page.author.name }}</div>
        <div v-if="page.published" class="byline"><label>Published:</label> {{ page.published }}</div>
        <div v-html="page.main_content"></div>
        <div v-if="page.author.bio || page.author.title" style="height: 12em;">
            <div class="divider"></div>
            <div v-if="page.author.image_url" class="left"><img :src="page.author.image_url" :alt="page.author.name + ' author image'" class="author-image"/></div>
            <div class="citation">{{ page.author.bio || page.author.title }}</div>
        </div>
    </div>
</div>
</div>
</template>
<script>
export default {
    name: 'page',
    data() {
        return {
            loading: true,
            message: '',
            page: {}
        }
    },
    created() {
        window.scrollTo(0, 0);
        this.apiCall({ destination: 'get_page_content', data: { link_name: this.$route.params.link_name } }).then(obj => {
            this.loading = false
            if (obj.status == 'success') {
                this.page = obj.page
            } else {
                this.message = obj.message
            }
        })
    },
    computed: {
    }
}
</script>
<style lang="scss" scoped>
</style>
