<template>
<div class="main content-page coming-soon">
    <h1>Website Coming Soon!</h1>
    <div class="coming-soon">
        <img class="logo" src="@/assets/gap-lion-logo1.png" />
    </div>
</div>
</template>
<script>
export default {
  name: 'ComingSoon'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
div.coming-soon {
    text-align: center;
    padding: 3em 0;
    img { width: 90%; max-width: 30em; }
}
</style>
